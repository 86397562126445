import React from "react"
//import { Link } from "gatsby"
import PropTypes from "prop-types"

// import MainNav from "./mainnav"
// import * as style from "./header.module.css"

// import { elastic as Menu } from 'react-burger-menu'
import NavMenu from "./NavMenu"
import * as styles from "./header.module.css"


import "./react-burgermenu.css"


const Header = ({  siteTitle, siteDescription, menuLinks }) => (
  <header id="site-header" >
  {/* <header id="site-header" className={style.masthead} > */}



<NavMenu menuLinks={menuLinks}/>


<div className={styles.masthead_info}>
{/* <div className={styles.site_title}>{siteTitle}</div> */}

</div>
 
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
}

Header.defaultProps = {
  // siteTitle: ``,
  siteDescription: ``,
}

export default Header
