import React from "react";
import { Link } from "gatsby";

import { elastic as Menu } from "react-burger-menu";
/* https://github.com/negomi/react-burger-menu */


import "./react-burgermenu.css";

const NavMenu = ({menuLinks}) => {
  return (
    <Menu key={menuLinks}>
      {menuLinks.map(properties => (
        <Link key={properties.name} to={properties.link}>{properties.name}</Link>
      ))}
   
    </Menu>
  );
};


export default NavMenu;
